// $background-color: #222831;
$background-color: #30475e;
$header-color: #222831;
$font-color: #ececec;
$image-color: #0E6D86;

html, body {
	font-family: 'Roboto', sans-serif;
	color: $font-color;
	background-color: $background-color;
}


@mixin resize-320 {
	.title {
		width: 100vw;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $header-color;
		padding: 0.5em 0.5em;
		h1 {
			font-size: 0.65em;
			text-transform: uppercase;
			letter-spacing: 0.1em;
		}
		nav {
			.button {
				font-size: 0.5em;
				text-decoration: none;
				margin: 0;
				color: $font-color;
				letter-spacing: 0.1em;
			}
		}
	}
	.content {
		padding-top: 3em;
		margin-top: 20%;
		flex-direction: column;
		.gamedropdown {
			margin-bottom: 1em;
			width: 100%;
			.form {
				width: 100%;
				.select {
					border: 0.1em solid;
					text-transform: uppercase;
					font-weight: 700;
					letter-spacing: 0.1em;
					color: $font-color;
				}
			}
		}
		.translator {
			width: 100%;
			.textfield {
				width: 100%;
			}
		}
	}
	.text {
		margin: 2em 0; 
		font-size: 0.75em;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.1em;
	}
	.imageoutput {
		text-align: center;
		.images {
			padding: 0.5em;
			display: inline-block;
			img {
				padding: 0.2em;
				&:hover{
					background-color: $image-color;
				}
			}
		}
	}
}

@mixin resize-480 {
	.title {
		width: 100vw;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $header-color;
		padding: 0.5em 0.5em;
		h1 {
			font-size: 0.75em;
			text-transform: uppercase;
			letter-spacing: 0.2em;
		}
		nav {
			.button {
				font-size: 0.6em;
				text-decoration: none;
				margin: auto 0.5em;
				color: $font-color;
				letter-spacing: 0.2em;
			}
		}
	}
	.content {
		padding-top: 3em;
		margin-top: 20%;
		flex-direction: column;
		.gamedropdown {
			margin-bottom: 1em;
			width: 100%;
			.form {
				width: 100%;
				.select {
					border: 0.1em solid;
					text-transform: uppercase;
					font-weight: 700;
					letter-spacing: 0.1em;
					color: $font-color;
				}
			}
		}
		.translator {
			width: 100%;
			.textfield {
				width: 100%;
			}
		}
	}
	.text {
		margin: 2em 0; 
		font-size: 0.75em;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.15em;
	}
	.imageoutput {
		.images {
			padding: 0.5em;
			display: inline-block;
			img {
				padding: 0.2em;
			}
		}
	}
}

@mixin resize-600 {
	.title {
		width: 100vw;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $header-color;
		padding: 0.5em 1em;
		h1 {
			font-size: 0.85em;
			text-transform: uppercase;
			letter-spacing: 0.3em;
		}
		nav {
			.button {
				font-size: 0.7em;
				text-decoration: none;
				margin: auto 0.75em;
				color: $font-color;
				letter-spacing: 0.3em;
			}
		}
	}
	.content {
		padding-top: 3em;
		margin-top: 20%;
		flex-direction: column;
		.gamedropdown {
			margin-bottom: 1em;
			width: 100%;
			.form {
				width: 100%;
				.select {
					border: 0.1em solid;
					text-transform: uppercase;
					font-weight: 700;
					letter-spacing: 0.1em;
					color: $font-color;
				}
			}
		}
		.translator {
			width: 100%;
			.textfield {
				width: 100%;
			}
		}
	}
	.text {
		margin: 2em 0; 
		font-size: 0.85em;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.25em;
	}
	.imageoutput {
		.images {
			padding: 0.5em;
			display: inline-block;
			img {
				padding: 0.2em;
			}
		}
	}
}

@mixin resize-801 {
	.title {
		width: 100vw;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $header-color;
		padding: 0.75em 1.5em;
		h1 {
			font-size: 0.85em;
			text-transform: uppercase;
			letter-spacing: 0.3em;
		}
		nav {
			.button {
				font-size: 0.7em;
				text-decoration: none;
				margin: auto 1em;
				color: $font-color;
				letter-spacing: 0.3em;
			}
		}
	}
	.content {
		padding-top: 3em;
		margin-top: 20%;
		flex-direction: column;
		.gamedropdown {
			margin-bottom: 1em;
			width: 100%;
			.form {
				width: 100%;
				.select {
					border: 0.1em solid;
					text-transform: uppercase;
					font-weight: 700;
					letter-spacing: 0.1em;
					color: $font-color;
				}
			}
		}
		.translator {
			width: 100%;
			.textfield {
				width: 100%;
			}
		}
	}
	.text {
		margin: 2em 0; 
		font-size: 0.85em;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.25em;
	}
	.imageoutput {
		.images {
			padding: 0.5em;
			display: inline-block;
			img {
				padding: 0.5em;
			}
		}
	}
}

@mixin resize-1025 {
	.title {
		width: 100vw;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $header-color;
		padding: 0.75em 1.5em;
		h1 {
			font-size: 0.85em;
			text-transform: uppercase;
			letter-spacing: 0.4em;
		}
		nav {
			.button {
				font-size: 0.7em;
				text-decoration: none;
				margin: auto 1em;
				color: $font-color;
				letter-spacing: 0.4em;
			}
		}
	}
	.content {
		padding-top: 3em;
		margin-top: 15%;
		flex-direction: column;
		.gamedropdown {
			margin-bottom: 1em;
			width: 100%;
			.form {
				width: 100%;
				.select {
					border: 0.1em solid;
					text-transform: uppercase;
					font-weight: 700;
					letter-spacing: 0.1em;
					color: $font-color;
				}
			}
		}
		.translator {
			width: 100%;
			.textfield {
				width: 100%;
			}
		}
	}
	.text {
		margin: 2em 0; 
		font-size: 0.85em;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.35em;
	}
	.imageoutput {
		.images {
			padding: 0.5em;
			display: inline-block;
			img {
				padding: 0.2em;
			}
		}
	}
}

.app {
	@media (min-width: 320px) {
		@include resize-320();
	}
	@media (min-width: 480px) {
		@include resize-480();
	}
	@media (min-width: 600px) {
		@include resize-600();
	}
	@media (min-width: 801px) {
		@include resize-801();
	}
	@media (min-width: 1025px) {
		@include resize-1025();
	}
}